import React, { useEffect, useState, Fragment } from "react";
import updateIcon from "../../../images/icons/renew.png";
import infoIcon from "../../../images/info_hollow.svg";
import { logout } from "../../utils/auth-utils";

const UpdateButton = ({ message, children, className, goDoLogout = false }) => {
  const [isUpdateAvailable, setIsUpdateAvailable] = useState(false);
  const [count, setCount] = useState(2);
  useEffect(() => {
    const interval = setInterval(() => {
      const isUpdateAvailable =
        document.getElementById("___gatsby").dataset.updateAvailable == "true";
      if (isUpdateAvailable || (goDoLogout != undefined && goDoLogout)) {
        setIsUpdateAvailable(true);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const Timer = () => {
    setTimeout(() => setCount(count - 1), 2000);
    if (count == 0) {
      if (goDoLogout) logout();
      window.location.reload(true);
    }
    return <p>{count}</p>;
  };

  return (
    <div
      className={`${className} ${
        isUpdateAvailable ? `pointer-events-none` : ``
      }`}
    >
      {isUpdateAvailable && (
        <div
          className={`absolute bg-white shadow-2xl rounded-xl w-72 ${
            isUpdateAvailable ? `pointer-events-auto` : ``
          } ${
            goDoLogout != undefined && goDoLogout
              ? ` md:w-79 md:px-2 h-64 `
              : ` md:w-88 md:px-16 h-72 `
          }`}
          style={{
            zIndex: "1001",
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
            margin: "auto",
          }}
        >
          <div className="flex justify-center items-center w-full h-full flex-col">
            <div className="w-full flex justify-center md:my-0 md:mb-4 my-5">
              <img
                src={
                  goDoLogout != undefined && goDoLogout ? infoIcon : updateIcon
                }
                alt="oops!"
                className={
                  goDoLogout != undefined && goDoLogout
                    ? "w-16 h-16"
                    : "w-24 h-24"
                }
              />
            </div>
            <div className="w-full flex-col justify-center items-center md:px-4 px-8 text-black-400 space-y-6">
              <div
                className={
                  goDoLogout != undefined && goDoLogout
                    ? "text-center"
                    : "w-full flex justify-center font-roboto text-sm font-normal text-center"
                }
              >
                {message}
              </div>
              <div className="px-1 w-full flex justify-center">
                <button
                  className="w-12 h-12 bg-tealCol-100 text-base"
                  style={{
                    backgroundColor: "rgb(56, 125, 242)",
                    color: "#FFFFFF",
                    borderRadius: "50%",
                    WebkitTapHighlightColor: "transparent",
                    pointerEvents: "none",
                  }}
                >
                  {Timer()}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

export default UpdateButton;
